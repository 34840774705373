import React, { useState } from "react";
import {
  Box,
  FormControl,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { Company, Roles } from "../../screens/AddUserScreen/AddUserScreen";
import Grid from "@mui/material/Grid2";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import PlainTextButton from "../Buttons/PlainTextButton";
import ProvideButton from "../Buttons/ProvideButton";

interface FormData {
  title: string;
  roles: Roles[];
  companyData: Company[];
}

interface FormReturnData {
  title: string;
  roles: Roles[];
  companyId: string;
}

interface AddUserCompanyRolesProps {
  onStep: (step: string) => void;
  formData: FormData;
  onFormSubmit: (data: FormReturnData) => void;
}

const AddUserCompanyRoles: React.FC<AddUserCompanyRolesProps> = ({
  onStep,
  formData,
  onFormSubmit,
}) => {
  const [title, setTitle] = useState(formData.title);
  const [companyId, setCompanyId] = useState<string>("");
  const [switchState, setSwitchState] = useState(formData.roles);

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedCompany = formData.companyData.find(
      (company) => company.name === event.target.value,
    );
    if (!selectedCompany) return;

    setTitle(event.target.value);
    setCompanyId(selectedCompany.id);
  };

  const handleToggle = (value: string) => {
    setSwitchState((prev) =>
      prev.map((role) =>
        role.name === value ? { ...role, checked: !role.checked } : role,
      ),
    );
  };

  const formSubmit = () => {
    onFormSubmit({
      title,
      roles: switchState,
      companyId,
    });
  };

  const saveAndContinue = () => {
    formSubmit();
    onStep("teams");
  };

  const goBack = () => {
    formSubmit();
    onStep("contactDetails");
  };

  return (
    <Stack spacing={4} sx={{ maxWidth: 480 }}>
      <Typography variant="h4" component="h4">
        2. Company & Roles
      </Typography>
      <FormControl variant="outlined">
        <TextField
          id="title"
          select
          label="Title"
          value={title}
          onChange={handleTitleChange}
          slotProps={{
            select: {
              native: true,
            },
          }}
        >
          {formData.companyData.map((company) => (
            <option key={company.id} value={company.name}>
              {company.name}
            </option>
          ))}
        </TextField>
      </FormControl>

      <Stack spacing={2} sx={{ maxWidth: 293 }}>
        <Typography variant="body5">User Roles</Typography>
        {switchState.map(({ name, uuid, checked }) => (
          <Box
            key={uuid}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography>{name}</Typography>
            <Switch
              size="medium"
              checked={checked}
              onChange={() => handleToggle(name)}
            />
          </Box>
        ))}
      </Stack>

      <Grid container spacing={3}>
        <Grid size={{ xs: 12, sm: 6 }}>
          <ProvideButton onClick={saveAndContinue} variant="contained">
            CONTINUE
            <ArrowForwardRoundedIcon sx={{ ml: 1 }} />
          </ProvideButton>
        </Grid>
      </Grid>
      <PlainTextButton onClick={goBack}>
        <ChevronLeftIcon />
        Back
      </PlainTextButton>
    </Stack>
  );
};

export default AddUserCompanyRoles;
