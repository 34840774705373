import React, { ReactNode, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router";
import { useAppSelector, useAppDispatch } from "../../hooks/useRedux";
import { RootState } from "../../store/store";
import { setAuthState, setRedirectPath } from "../../store/slices/authSlice";
import useFetchUser from "../../hooks/api/useFetchUser";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

interface AuthWrapperProps {
  children: ReactNode;
  isProtected: boolean;
  ignoredRoutes: string[];
}

const AuthWrapper: React.FC<AuthWrapperProps> = ({
  children,
  isProtected,
  ignoredRoutes,
}) => {
  const isAuthenticated = useAppSelector(
    (state: RootState) => state.auth.isAuthenticated,
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { data, loading } = useFetchUser();
  const [authInitialized, setAuthInitialized] = useState(false);
  const [navigationCompleted, setNavigationCompleted] = useState(false);

  const isIgnoredRoute = (currentPath: string) => {
    return ignoredRoutes.some((route) => currentPath.includes(route));
  };

  // Initialize authentication state and dispatch to redux store
  useEffect(() => {
    if (!loading) {
      dispatch(setAuthState(!!data));
      setAuthInitialized(true);
    }
  }, [data, loading]);

  // Handle protected route access and block rendering until
  // navigation is complete
  useEffect(() => {
    // wait until authInitialized in redux
    if (!authInitialized) return;

    // navigate to ignored routes regardless of protetion
    if (isIgnoredRoute(location.pathname)) {
      setNavigationCompleted(true);
      return;
    }

    // handle protected and unprotected routes, redirect
    // to login or dashboard depending on auth
    // store location to navigate to once logged in
    if (isProtected && !isAuthenticated) {
      dispatch(setRedirectPath(location.pathname));
      navigate("/");
    } else if (!isProtected && isAuthenticated) {
      navigate("/dashboard");
    } else {
      setNavigationCompleted(true);
    }
  }, [authInitialized, location]);

  // Prevent rendering children until loading, authentication, and
  // navigation are resolved
  if (loading || !navigationCompleted) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress color="primary" />
      </Box>
    );
  }

  return <>{children}</>;
};

export default AuthWrapper;
