import useAxios from "axios-hooks";
import { EditUserPayload } from "../../components/UserSettings/UserSettingsMain";

const useUser = (id: string | undefined) => {
  const URL = `/api/v1/users/${id}`;

  const [
    { data: userData, loading: fetchingUser, error: fetchUserError },
    fetchUser,
  ] = useAxios(
    {
      url: URL,
      method: "GET",
    },
    { manual: true },
  );

  const [{ data: editData, loading: editing, error: editError }, editUser] =
    useAxios(
      {
        url: URL,
        method: "PUT",
      },
      { manual: true },
    );

  const handleFetchUser = async () => {
    try {
      await fetchUser();
    } catch (error) {
      console.error("Fetching user data failed:", error);
      return { error };
    }
  };

  const handleEditUser = async (payload: EditUserPayload) => {
    const { firstName, lastName, dob, phone, newPassword, currentPassword } =
      payload;

    try {
      await editUser({
        data: {
          first_name: firstName,
          last_name: lastName,
          date_of_birth: dob,
          phone_number: phone,
          ...(newPassword && {
            password: newPassword,
          }),
          ...(currentPassword && {
            current_password: currentPassword,
          }),
        },
      });
    } catch (error) {
      console.error("Edit user request failed:", error);
      return { error };
    }
  };

  return {
    handleEditUser,
    editData,
    editing,
    editError,
    handleFetchUser,
    userData,
    fetchingUser,
    fetchUserError,
  };
};

export default useUser;
