import useAxios from "axios-hooks";

const useCreateUser = () => {
  const [
    { loading: creatingUser, error: createUserError, data: createUserData },
    register,
  ] = useAxios(
    {
      url: "/api/v1/users",
      method: "POST",
    },
    { manual: true },
  );

  const handleCreateUser = async (
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    dob: string,
    roles: string[],
  ) => {
    try {
      await register({
        data: JSON.stringify({
          first_name: firstName,
          last_name: lastName,
          email,
          phone_number: phone,
          date_of_birth: dob,
          roles,
        }),
      });

      console.debug("Created successfully:", createUserData);
      return { success: true, data: createUserData };
    } catch (error) {
      console.error("Creation failed:", error);
      return { success: false, error: createUserError };
    }
  };

  return { handleCreateUser, creatingUser, createUserError, createUserData };
};

export default useCreateUser;
