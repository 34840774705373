import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router";
import { store } from "./store/store";
import { Provider } from "react-redux";
import { ThemeProvider } from "@mui/material/styles";
import { provideTheme } from "./themes/theme";
import LoginScreen from "./screens/LoginScreen/LoginScreen";
import DashboardScreen from "./screens/DashboardScreen/DashboardScreen";
import SignUpScreen from "./screens/SignUpScreen/SignUpScreen";
import LoginSignupToolbar from "./components/LoginSignupToolbar/LoginSignupToolbar";
import SideNavigation from "./components/SideNavigation/SideNavigation";
import AuthWrapper from "./components/AuthWrapper/AuthWrapper";
import UserSettingsScreen from "./screens/UserSettingsScreen/UserSettingsScreen";
import VerifyEmailScreen from "./screens/VerifyEmailScreen/VerifyEmailScreen";
import UserListScreen from "./screens/UserListScreen/UserListScreen";
import ResetPasswordScreen from "./screens/ResetPasswordScreen/ResetPasswordScreen";
import AddUserScreen from "./screens/AddUserScreen/AddUserScreen";

// Routes that can be accessed, regardless of auth status
const ignoredRoutesList = ["/password-reset", "/verify-email"];

const App = () => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={provideTheme}>
        <Router>
          <Routes>
            {/* Public routes */}
            <Route
              path="/"
              element={
                <AuthWrapper
                  isProtected={false}
                  ignoredRoutes={ignoredRoutesList}
                >
                  <LoginSignupToolbar />
                </AuthWrapper>
              }
            >
              <Route index element={<LoginScreen />} />
              <Route
                path="/sign-up"
                element={<SignUpScreen mode="sign-up" />}
              />
              <Route
                path="/user-invites/:id/accept"
                element={<SignUpScreen mode="invite" />}
              />
              <Route
                path="/verify-email/:id/:hash"
                element={<VerifyEmailScreen />}
              />
              <Route
                path="/authenticate-once/:id"
                element={<VerifyEmailScreen />}
              />
              <Route
                path="/password-reset/:token"
                element={<ResetPasswordScreen />}
              />
            </Route>

            {/* Protected routes */}
            <Route
              element={
                <AuthWrapper
                  isProtected={true}
                  ignoredRoutes={ignoredRoutesList}
                >
                  <SideNavigation />
                </AuthWrapper>
              }
            >
              <Route
                path="/dashboard"
                element={<DashboardScreen infoText="dashboard" />}
              />
              <Route path="/settings" element={<UserSettingsScreen />} />
              <Route path="/users" element={<UserListScreen />} />
              <Route path="/add-user" element={<AddUserScreen />} />
            </Route>
          </Routes>
        </Router>
      </ThemeProvider>
    </Provider>
  );
};

export default App;
