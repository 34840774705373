import React, { useEffect, useState } from "react";
import RootAuthScreen from "../RootScreen/RootAuthScreen";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import UserIcon from "../../components/Icons/UserIcon/UserIcon";
import DeleteIcon from "@mui/icons-material/Delete";
import PlainTextButton from "../../components/Buttons/PlainTextButton";
import AddUserCompanyRoles from "../../components/AddUser/AddUserCompanyRoles";
import AddUserContactDetails from "../../components/AddUser/AddUserContactDetails";
import AddUserTeams from "../../components/AddUser/AddUserTeams";
import AddUserSendLogin from "../../components/AddUser/AddUserSendLogin";
import useFetchCompanies from "../../hooks/api/useFetchCompanies";
import ModalContainer from "../../components/Modal/ModalContainer";
import DropShadowBox from "../../components/DropShadowBox/DropShadowBox";
import { AnimatedBounceContainer } from "../../styled/AnimatedBounceContainer.styled";
import CheckmarkCircle from "../../components/Icons/CheckmarkCircle/CheckmarkCircle";
import ProvideButton from "../../components/Buttons/ProvideButton";
import useFetchRoles from "../../hooks/api/useFetchRoles";
import useCreateUser from "../../hooks/api/useCreateUser";
import RedCrossIcon from "../../components/Icons/RedCrossIcon/RedCrossIcon";

enum Step {
  ContactDetails = "contactDetails",
  CompanyRoles = "companyRoles",
  Teams = "teams",
  SendLogin = "sendLogin",
}

export interface Company {
  id: string;
  name: string;
}

export interface Roles {
  name: string;
  uuid: string;
  checked: boolean;
}

const AddUserScreen: React.FC = () => {
  const { companyList, companyLoading, companyError } = useFetchCompanies();
  const { rolesList, rolesLoading, rolesError } = useFetchRoles();
  const { handleCreateUser, creatingUser, createUserError, createUserData } =
    useCreateUser();

  const { ContactDetails, CompanyRoles, Teams, SendLogin } = Step;

  const [step, setStep] = useState<Step>(ContactDetails);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const defaultContactDetails = {
    firstName: "",
    lastName: "",
    email: "",
    dob: "",
    phone: "",
  };
  const defaultCompanyRoles = {
    title: "",
    roles: [] as Roles[],
    companyData: [] as Company[],
  };
  const defaultSendLoginData = {
    email: "",
    validateSwitch: false,
  };

  const [contactDetails, setContactDetails] = useState(defaultContactDetails);
  const [companyRolesData, setCompanyRolesData] = useState(defaultCompanyRoles);
  const [sendLoginData, setSendLoginData] = useState(defaultSendLoginData);

  useEffect(() => {
    if (companyList) {
      const formattedData: Company[] = companyList.data.map(
        ({ id, name }: Company) => ({
          id,
          name,
        }),
      );
      setCompanyRolesData((prev) => ({
        ...prev,
        companyData: formattedData,
      }));
    }
  }, [companyList]);

  useEffect(() => {
    if (rolesList) {
      const formattedRoles: Roles[] = rolesList.data.map(
        ({ name, uuid }: Roles) => ({
          name,
          uuid,
          checked: false,
        }),
      );
      setCompanyRolesData((prev) => ({
        ...prev,
        roles: formattedRoles,
      }));
    }
  }, [rolesList]);

  const changeStep = (nextStep: string) => {
    if (Object.values(Step).includes(nextStep as Step)) {
      setStep(nextStep as Step);
    }
  };

  const handleContactDetailsChange = (
    updatedData: Partial<typeof contactDetails>,
  ) => {
    setContactDetails((prev) => ({ ...prev, ...updatedData }));
  };

  const handleCompanyDetailsChange = (data: {
    title: string;
    companyId: string;
    roles: Roles[];
  }) => {
    setCompanyRolesData((prev) => ({
      ...prev,
      title: data.title,
      roles: data.roles,
    }));
  };

  const handleSendLoginChange = () => {
    postData();
  };

  const postData = async () => {
    const selectedRoles = companyRolesData.roles
      .filter((role) => role.checked)
      .map((role) => role.name);

    handleCreateUser(
      contactDetails.firstName,
      contactDetails.lastName,
      contactDetails.email,
      contactDetails.phone,
      contactDetails.dob,
      selectedRoles,
    );

    setIsModalOpen(true);
  };

  const deleteUser = () => {
    setContactDetails(defaultContactDetails);
    setSendLoginData(defaultSendLoginData);
    changeStep("contactDetails");
  };

  const handleDoneButton = () => {
    if (!createUserError && createUserData) {
      deleteUser();
    }
    setIsModalOpen(false);
  };

  if (companyLoading || rolesLoading) {
    return (
      <RootAuthScreen>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <CircularProgress color="primary" />
        </Box>
      </RootAuthScreen>
    );
  }

  if (companyError || rolesError) {
    return (
      <RootAuthScreen>
        {companyError && (
          <Typography color="error">
            {companyError.response?.data.message}
          </Typography>
        )}
        {rolesError && (
          <Typography color="error">
            {rolesError.response?.data.message}
          </Typography>
        )}
      </RootAuthScreen>
    );
  }

  return (
    <RootAuthScreen>
      <Grid container alignItems="center" justifyContent="end" spacing={3}>
        <PlainTextButton onClick={deleteUser}>
          <DeleteIcon />
          DELETE
        </PlainTextButton>
      </Grid>
      <Stack spacing={4}>
        <Box
          display="flex"
          alignItems="center"
          gap={1}
          flexDirection="row"
          sx={{ flexWrap: "wrap", justifyContent: "flex-start" }}
        >
          <UserIcon />
          <Typography component="h1" variant="h1" color="black">
            Create User
          </Typography>
        </Box>
        {step === ContactDetails && (
          <AddUserContactDetails
            onStep={changeStep}
            formData={contactDetails}
            onFormSubmit={handleContactDetailsChange}
          />
        )}
        {step === CompanyRoles && (
          <AddUserCompanyRoles
            onStep={changeStep}
            formData={companyRolesData}
            onFormSubmit={handleCompanyDetailsChange}
          />
        )}
        {step === Teams && <AddUserTeams onStep={changeStep} />}
        {step === SendLogin && (
          <AddUserSendLogin
            onStep={changeStep}
            formData={contactDetails.email}
            onFormSubmit={handleSendLoginChange}
          />
        )}
      </Stack>
      <ModalContainer isOpen={isModalOpen}>
        <DropShadowBox minWidth={200}>
          <Stack alignItems="center" spacing={2}>
            <AnimatedBounceContainer>
              {creatingUser ? (
                <CircularProgress color="primary" />
              ) : createUserError ? (
                <RedCrossIcon />
              ) : (
                <CheckmarkCircle />
              )}
            </AnimatedBounceContainer>

            {creatingUser ? (
              <Typography textAlign="center">Creating user...</Typography>
            ) : createUserError ? (
              <Typography color="error" textAlign="center">
                {createUserError?.response?.data?.message ||
                  "An error occurred."}
              </Typography>
            ) : (
              <Box>
                <Typography textAlign="center">
                  Login details sent to:
                </Typography>
                <Typography textAlign="center">
                  {sendLoginData.email}
                </Typography>
              </Box>
            )}

            <ProvideButton
              variant="contained"
              onClick={handleDoneButton}
              sx={{ width: 157 }}
              disabled={creatingUser}
            >
              <strong>DONE</strong>
            </ProvideButton>
          </Stack>
        </DropShadowBox>
      </ModalContainer>
    </RootAuthScreen>
  );
};

export default AddUserScreen;
