import React from "react";
import { Outlet, useMatch, useNavigate } from "react-router";
import { AppBar, Typography, Box } from "@mui/material";
import Grid from "@mui/material/Grid2";
import ProvideLogoText from "../Icons/ProvideLogo/ProvideLogoText";
import { loginData, pillData } from "./data/menuData";
import ProvideLogo from "../Icons/ProvideLogo/ProvideLogo";
import PillNavigation from "../PillNavigation/PillNavigation";
import { useAppDispatch, useAppSelector } from "../../hooks/useRedux";
import { RootState } from "../../store/store";
import { setUserType } from "../../store/slices/signupSlice";

const LoginSignupToolbar: React.FC = () => {
  const isSignUp = useMatch("/sign-up");
  const isVerifyEmail = useMatch("/verify-email/*");
  const isInvite = useMatch("/user-invites/*");

  const userType = useAppSelector((state: RootState) => state.signup.userType);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  function handlePillClick(option: string): void {
    dispatch(setUserType(option));
  }

  function handleHomeClick(): void {
    navigate("/");
  }

  return (
    <>
      <AppBar>
        <Grid
          container
          alignItems="center"
          spacing={!isSignUp ? { md: 2.5, lg: 10 } : 3}
          px={2}
          py={{ xs: 2.5, md: 5 }}
        >
          <Box onClick={handleHomeClick} sx={{ cursor: "pointer" }}>
            <Box sx={{ display: { xs: "none", md: "block" } }}>
              <ProvideLogoText width={212} />
            </Box>
            <Box sx={{ display: { xs: "block", md: "none" } }}>
              <ProvideLogo width={30} />
            </Box>
          </Box>

          {!isSignUp && !isVerifyEmail && !isInvite ? (
            <Box sx={{ display: { xs: "none", md: "block" } }}>
              <Grid container spacing={{ md: 2.5, lg: 10 }}>
                {loginData.map((item) => (
                  <Typography
                    key={item.id}
                    component="h2"
                    variant={item.variant}
                  >
                    {item.text}
                  </Typography>
                ))}
              </Grid>
            </Box>
          ) : (
            isSignUp && (
              <PillNavigation
                reduxSelector={userType}
                pillActiveColor="purple"
                buttons={pillData}
                onClick={handlePillClick}
              />
            )
          )}
        </Grid>
      </AppBar>
      <Outlet />
    </>
  );
};

export default LoginSignupToolbar;
