import React from "react";
import { Stack, Typography } from "@mui/material";

import Grid from "@mui/material/Grid2";
import ProvideButton from "../Buttons/ProvideButton";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import PlainTextButton from "../Buttons/PlainTextButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

interface AddUserSendLoginProps {
  onStep: (step: string) => void;
  formData: string;
  onFormSubmit: () => void;
}

const AddUserSendLogin: React.FC<AddUserSendLoginProps> = ({
  onStep,
  formData,
  onFormSubmit,
}) => {
  const submit = () => {
    onFormSubmit();
  };

  const goBack = () => {
    onStep("teams");
  };

  return (
    <Stack spacing={4} sx={{ maxWidth: 480 }}>
      <Typography variant="h4" component="h4">
        4. Send Login
      </Typography>
      <Typography variant="body3">
        Click Submit to send an invitation to <strong>{formData}</strong>
      </Typography>
      <Grid container spacing={3}>
        <Grid size={{ xs: 12, sm: 6 }}>
          <ProvideButton onClick={submit} variant="contained">
            SUBMIT
            <ArrowForwardRoundedIcon sx={{ ml: 1 }} />
          </ProvideButton>
        </Grid>
      </Grid>
      <PlainTextButton onClick={goBack}>
        <ChevronLeftIcon />
        Back
      </PlainTextButton>
    </Stack>
  );
};

export default AddUserSendLogin;
