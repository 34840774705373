import useAxios from "axios-hooks";

const useFetchRoles = () => {
  const [{ data: rolesList, loading: rolesLoading, error: rolesError }] =
    useAxios({
      url: "/api/v1/roles",
      method: "GET",
    });

  return { rolesList, rolesLoading, rolesError };
};

export default useFetchRoles;
