import React, { useEffect, useState } from "react";
import {
  FormControl,
  FormHelperText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import Grid from "@mui/material/Grid2";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import useValidateInput from "../../hooks/useValidateInput";
import ProvideButton from "../Buttons/ProvideButton";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";

interface ContactDetailsFormData {
  firstName: string;
  lastName: string;
  email: string;
  dob: string;
  phone: string;
}

interface AddUserContactDetailsProps {
  onStep: (step: string) => void;
  formData: ContactDetailsFormData;
  onFormSubmit: (data: ContactDetailsFormData) => void;
}

const AddUserContactDetails: React.FC<AddUserContactDetailsProps> = ({
  onStep,
  formData,
  onFormSubmit,
}) => {
  const [areInputsValidated, setAreInputsValidated] = useState<boolean>(false);
  const [localFormData, setLocalFormData] = useState(formData);
  const { errors, validateField } = useValidateInput();

  useEffect(() => {
    const isFieldValid = (field: string, error: boolean) =>
      !error && field.trim() !== "";

    const emailValid = isFieldValid(localFormData.email, errors.email?.error);
    const phoneValid = isFieldValid(localFormData.phone, errors.phone?.error);
    const namesValid =
      isFieldValid(localFormData.firstName, errors.firstName?.error) &&
      isFieldValid(localFormData.lastName, errors.lastName?.error);

    setAreInputsValidated(
      emailValid && namesValid && phoneValid && localFormData.dob !== "",
    );
  }, [errors, localFormData]);

  const handleFormChange =
    (field: "firstName" | "lastName" | "phone" | "email") =>
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      const value = evt.target.value;
      validateField(field, value);
      setLocalFormData((prev) => ({ ...prev, [field]: value }));
    };

  const handleDateChange = (newDate: Dayjs | null) => {
    if (newDate) {
      setLocalFormData((prev) => ({
        ...prev,
        dob: dayjs(newDate).format("YYYY-MM-DDTHH:mm:ss"),
      }));
    }
  };

  const saveAndContinue = () => {
    onFormSubmit(localFormData);
    onStep("companyRoles");
  };

  return (
    <Stack spacing={4} sx={{ maxWidth: 480 }}>
      <Typography variant="h4" component="h4">
        1. Contact Details
      </Typography>
      <FormControl fullWidth variant="outlined">
        <TextField
          id="email"
          autoComplete="email"
          label="Email Address"
          value={localFormData.email}
          variant="outlined"
          onChange={handleFormChange("email")}
          placeholder="john.doe@gmail.com"
          fullWidth
        />
        {errors.email?.error && (
          <FormHelperText error>{errors.email.helperText}</FormHelperText>
        )}
      </FormControl>
      <Grid container spacing={3}>
        <Grid size={{ xs: 12, sm: 6 }}>
          <FormControl fullWidth variant="outlined">
            <TextField
              id="first-name"
              autoComplete="first-name"
              label="First Name"
              placeholder="John"
              variant="outlined"
              value={localFormData.firstName}
              onChange={handleFormChange("firstName")}
              error={errors.firstName?.error}
              fullWidth
            />
            {errors.firstName?.error && (
              <FormHelperText error>
                {errors.firstName.helperText}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <FormControl fullWidth variant="outlined">
            <TextField
              id="last-name"
              autoComplete="family-name"
              label="Last Name"
              placeholder="Doe"
              variant="outlined"
              value={localFormData.lastName}
              onChange={handleFormChange("lastName")}
              error={errors.lastName?.error}
              fullWidth
            />
            {errors.lastName?.error && (
              <FormHelperText error>
                {errors.lastName.helperText}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid size={{ xs: 12, sm: 6 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Date of Birth"
              value={localFormData.dob ? dayjs(localFormData.dob) : null}
              onChange={handleDateChange}
              slotProps={{
                textField: {
                  sx: { width: "100%" },
                },
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <FormControl fullWidth variant="outlined">
            <TextField
              id="phone"
              autoComplete="phone"
              label="Phone Number"
              value={localFormData.phone}
              placeholder="+44 759 333 2124"
              variant="outlined"
              onChange={handleFormChange("phone")}
              error={errors.phone?.error}
              fullWidth
            />
            {errors.phone?.error && (
              <FormHelperText error>{errors.phone.helperText}</FormHelperText>
            )}
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid size={{ xs: 12, sm: 6 }}>
          <ProvideButton
            onClick={saveAndContinue}
            disabled={!areInputsValidated}
            variant="contained"
          >
            CONTINUE
            <ArrowForwardRoundedIcon sx={{ ml: 1 }} />
          </ProvideButton>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default AddUserContactDetails;
