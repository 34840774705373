import useAxios from "axios-hooks";
import { setUserData } from "../../store/slices/settingsSlice";
import { useAppDispatch } from "../useRedux";

const useAuthenticateOTPCode = () => {
  const dispatch = useAppDispatch();

  const [{ error: OTPError, data: OTPData }, authenticateOTPCode] = useAxios(
    {
      url: "/authenticate-otp",
      method: "POST",
    },
    { manual: true },
  );

  const handleAuthenticateOTPCode = async (token: string) => {
    try {
      const response = await authenticateOTPCode({
        data: JSON.stringify({ token }),
      });
      dispatch(setUserData(response.data.data));
      return { success: true };
    } catch (error) {
      console.error("Error authenticating code:", error);
    }
  };

  return { handleAuthenticateOTPCode, OTPError, OTPData };
};

export default useAuthenticateOTPCode;
