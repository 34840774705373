import React from "react";
import Grid from "@mui/material/Grid2";
import PlainTextButton from "../Buttons/PlainTextButton";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router";
import useLogout from "../../hooks/api/useLogout";
import { useAppDispatch } from "../../hooks/useRedux";
import { logout } from "../../store/slices/authSlice";
import CustomIconButton from "../Buttons/CustomIconButton";
import { useMediaQuery, useTheme } from "@mui/material";

const UserTopNavigation: React.FC = () => {
  const { handleLogout } = useLogout();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const onExitClick = () => {
    navigate("/dashboard");
  };

  const onLogoutClick = async () => {
    const result = await handleLogout();

    if (result.success) {
      dispatch(logout());
      navigate("/");
    }
  };

  return (
    <Grid
      container
      alignItems="center"
      justifyContent={isSmallScreen ? "end" : "space-between"}
      spacing={2}
    >
      <PlainTextButton onClick={onExitClick} withIcon>
        EXIT
      </PlainTextButton>
      <Grid
        container
        alignItems="center"
        justifyContent="flex-start"
        spacing={1}
      >
        <Grid>
          <PlainTextButton onClick={onLogoutClick} withIcon>
            LOGOUT
          </PlainTextButton>
        </Grid>
        <Grid>
          <CustomIconButton>
            <SearchIcon />
          </CustomIconButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UserTopNavigation;
