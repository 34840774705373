import useAxios from "axios-hooks";
import { setUserData } from "../../store/slices/settingsSlice";
import { setPermissions } from "../../store/slices/permissionsSlice";
import { useAppDispatch } from "../useRedux";

interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
  marketingOptIn: boolean;
}

const useRegister = () => {
  const dispatch = useAppDispatch();
  const [
    { loading: registering, error: registerError, data: registerData },
    register,
  ] = useAxios(
    {
      url: "/register",
      method: "POST",
    },
    { manual: true },
  );

  const handleRegister = async (formData: FormData) => {
    try {
      const response = await register({
        data: JSON.stringify({
          first_name: formData.firstName,
          last_name: formData.lastName,
          email: formData.email,
          password: formData.password,
          password_confirmation: formData.confirmPassword,
          preferences: {
            marketing_opt_in: formData.marketingOptIn,
          },
        }),
      });

      dispatch(setUserData(response.data.data));
      dispatch(setPermissions(response.data.data.permissions));
      return { success: true, data: registerData };
    } catch (error) {
      console.error("Registration failed:", error);
      return { success: false, error: registerError };
    }
  };

  return { handleRegister, registering, registerError, registerData };
};

export default useRegister;
