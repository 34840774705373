import React, { useEffect } from "react";
import { Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import LoginWave from "../../components/Icons/LoginWave/LoginWave";
import { useAppDispatch, useAppSelector } from "../../hooks/useRedux";
import { RootState } from "../../store/store";
import DisclaimerText from "../../components/DisclamerText/DisclamerText";
import DropdownInfoBox from "../../components/DropDownInfoBox/DropDownInfoBox";
import DropShadowBox from "../../components/DropShadowBox/DropShadowBox";
import SignUp from "../../components/SignUp/SignUp";
import {
  checkUrlForSignUpType,
  setUserType,
} from "../../store/slices/signupSlice";
import { useNavigate } from "react-router";
import RootUnauthScreen from "../RootScreen/RootUnauthScreen";

interface SignUpScreenProps {
  mode: "sign-up" | "invite";
}

const SignUpScreen: React.FC<SignUpScreenProps> = (props) => {
  const { mode } = props;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(checkUrlForSignUpType());
  }, [dispatch]);

  const userType = useAppSelector((state: RootState) => state.signup.userType);

  function handleSwitchUser(): void {
    const option = userType === "borrower" ? "broker" : "borrower";
    const queryString = `${location.pathname}?option=${option}`;
    navigate(queryString);
    dispatch(setUserType(option));
  }

  return (
    <>
      <LoginWave />
      <RootUnauthScreen>
        <Grid container justifyContent="center">
          <Stack
            spacing={5}
            sx={{
              width: {
                xs: "100%",
                md: 603,
                zIndex: 1,
              },
            }}
          >
            <Stack>
              <Typography component="h1" variant="h1">
                Create a Free Account
              </Typography>
              <Typography component="h2" variant="h2">
                Lets gets started. Are you ready to be a part of something new?
              </Typography>
            </Stack>
            <DropShadowBox>
              {userType === "broker" ? (
                <Typography>Broker SignUp</Typography>
              ) : (
                <SignUp mode={mode} />
              )}
            </DropShadowBox>
            <Typography
              component="span"
              sx={{
                gap: 0.75,
                pl: { xs: 0, md: 4 },
                color: "gray.dark",
              }}
            >
              Want to create a{" "}
              <span
                onClick={handleSwitchUser}
                style={{
                  cursor: "pointer",
                  fontWeight: "bold",
                  color: "black",
                }}
              >
                {userType === "borrower"
                  ? "Broker account instead?"
                  : "Borrower account instead?"}
              </span>
            </Typography>
            <DropdownInfoBox />
            <DisclaimerText />
          </Stack>
        </Grid>
      </RootUnauthScreen>
    </>
  );
};

export default SignUpScreen;
