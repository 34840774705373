import useAxios from "axios-hooks";

interface InviteData {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
  marketingOptIn: boolean;
}

const useInvite = (id: string | undefined) => {
  const [{ loading: inviting, error: inviteError, data: inviteData }, invite] =
    useAxios(
      {
        url: `/user-invites/${id}/accept`,
        method: "POST",
      },
      { manual: true },
    );

  const handleInvite = async (formData: InviteData) => {
    try {
      await invite({
        data: JSON.stringify({
          first_name: formData.firstName,
          last_name: formData.lastName,
          email: formData.email,
          password: formData.password,
          password_confirmation: formData.confirmPassword,
          preferences: {
            marketing_opt_in: formData.marketingOptIn,
          },
        }),
      });

      console.debug("Invite sent successfully:", inviteData);
      return { success: true, data: inviteData };
    } catch (error) {
      console.error("Invite failed:", error);
      return { success: false, error: inviteError };
    }
  };

  return { handleInvite, inviting, inviteError, inviteData };
};

export default useInvite;
