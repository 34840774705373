import useAxios from "axios-hooks";

const useFetchCompanies = () => {
  const [{ data: companyList, loading: companyLoading, error: companyError }] =
    useAxios({
      url: "/api/v1/companies",
      method: "GET",
    });

  return { companyList, companyLoading, companyError };
};

export default useFetchCompanies;
