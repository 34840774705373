import useAxios from "axios-hooks";

const useFetchInviteUser = (token: string | undefined) => {
  const URL = `/user-invites/${token}`;

  const [
    {
      data: inviteUserData,
      loading: fetchingInviteUser,
      error: fetchInviteUserError,
    },
    fetchInviteUser,
  ] = useAxios(
    {
      url: URL,
      method: "GET",
    },
    { manual: true },
  );

  const handleFetchInviteUser = async () => {
    try {
      await fetchInviteUser();
    } catch (error) {
      console.error("Fetching invited user data failed:", error);
      return { error };
    }
  };

  return {
    handleFetchInviteUser,
    inviteUserData,
    fetchingInviteUser,
    fetchInviteUserError,
  };
};

export default useFetchInviteUser;
